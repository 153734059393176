@use "../components/_mixins";
@use "../components/_extends";
@use "../components/_colors";
@use "../components/_main";
@use "../components/_heroSmall";
@use "../components/_modal";
@use "../components/_stickyFooterSearch";
@use "../components/_whiteHeader";
@use "../components/_get-in-touch";



.hero{max-height: 492px; min-height: 260px; margin-bottom: 91px;
    @media(max-width:1000px){margin-bottom: 50px;}
    @media(max-width:620px){margin-bottom: 30px;}
}

.hero{
    &.no-img{min-height: auto; height: auto; margin: 40px 0 0;
        .hero-content{
            h1{color:colors.$darkGray; @include mixins.font(3.4, 6.4, 130%, 400, 0, colors.$darkGray, "Magazine Grotesque"); padding: 0 30px; margin-bottom: 40px;
                @include mixins.mq(860){margin-bottom: 20px;}
                @include mixins.mq(374){margin-bottom: 15px;;}
            }
        }
    }
    &:before{content: none !important; }

    &.mid-content{align-items: flex-start; min-height: 500px; padding: 20px 78px 20px 116px;
        .hero-content{text-align:inherit; margin: 0; align-items: flex-start; max-height: 622px;
            h4{font-size: clamp(2.2rem, 4.5vw, 4.5rem); line-height: 120%; font-weight: 400; margin-bottom: 20px;
                @include mixins.mq(800){margin-bottom: 9px;}
            }
            a{@include mixins.font(1.4, 1.6, 100%, 600, 0, colors.$darkGray); text-align: center; border-radius: 2px; background-color: white; color:colors.$darkGray; padding: 19px 23px; text-transform: uppercase;}
            &.white-card{background-color: white; padding: 40px;
                a{background-color: colors.$darkGray; color:white;}
                @include mixins.mq(800){padding: 20px;}
            }  
            &.right{max-width: 514px; margin-left: auto;} 
        }
        @include mixins.mq(1100){padding: 0 40px;}
        @include mixins.mq(860){padding: 0 30px; max-height: 400px;}
        @include mixins.mq(400){padding: 0 20px;}
    }
}

@use "_mixins";
@use "_extends";
@use "_colors";
.hero{margin: 0 25px; border-radius: 10px; overflow: hidden; position: relative; display:flex; flex-direction:column; justify-content:center; @include mixins.bgRatio(310, 600, 41);
    .hero-content{max-width:1200px; margin: 0 auto; padding:20px 40px 20px; text-align:center; position: relative; z-index: 2; display: flex; flex-direction: column; height: 100%;
        h1{@include mixins.font(3.8, 8, 100%, 700, null,  var(--color-yellow), "Magazine Grotesque"); text-transform: uppercase; margin-bottom:19px; letter-spacing:0.1rem;
           
            @include mixins.mq(600){margin-bottom: 10px;}
        }
        h2{color:white;
            span{font-size: clamp(1.4rem, 1.6vw, 1.6rem) !important; line-height: 140%;
                &:last-of-type{margin-bottom: 0;}
            }
        }
        p{@include mixins.font(1.6, 2, 140%, null, null, white, "Magazine Grotesque"); line-height:3.6rem; padding: 0 50px;}
        &__award{display:flex; align-items:flex-end; gap:10px; margin:0 0; justify-content: center; flex:1;
            p{@include mixins.font(1.2, 1.2, 140%, null, null, white); text-align:left; padding:0; max-width: 175px;
              b{display:block; margin-top: 4px; font-weight:700;}
            }
            img{max-width: 68px;}
        }
        span{@include mixins.font(1.4, 1.6, 140%, 600, null, white); text-transform:uppercase; display:block; margin-bottom:17px; letter-spacing:0.16rem;
             i{text-transform:none; font-weight:400; font-family: "Magazine Grotesque", serif; letter-spacing:0;}
        }
        &>div{
            &:first-child{
                flex:2; display: flex; flex-direction: column; justify-content: center;
            }
        }
        @include mixins.mq(500) { padding: 20px; }
    }
    
    #gallery-button{ position: absolute; bottom:20px; right:20px; display: flex; gap:10px; 
        button {letter-spacing: 0; background: rgba(0, 0, 0, 0.75); border:none; align-items: center; align-self: flex-end; border-radius: 8px; height: 40px; padding: 4px 18px; cursor: pointer;
            span{font-size: 1.6rem; font-weight: 700; color:white; 
                @include mixins.mq(890){display: none;}
            }
            img{width: 18px;}
        }
    }
    &:before{
        content:"" !important; position:absolute; top:0; right:0; bottom:0; left:0; background-color: rgba(black, 0.2); pointer-events: none;
    } 
    @include mixins.mq(860){margin: 0 42px;}
    @include mixins.mq(620){margin: 0 30px;}
    @include mixins.mq(374){margin: 0 20px;}
}

